import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import SanityMuxPlayer from "sanity-mux-player";
import Picture from "../picture";
import CustomLink from "../link";
import LottieImage from "../lottie-image";
import CoreContainer from "../core-container";
import BlockContent from "../blocks/block-content";

const TITLE_SIZE = {
  Small: "small",
  Big: "big",
};

const ImageText = ({ data = {} }) => {
  const {
    text,
    link,
    title,
    Image,
    theme,
    video,
    picture,
    subTitle,
    mediaType,
    lottieFile,
    moduleWidth,
    iconTextList,
    titleHTMLTag,
    titleSize = TITLE_SIZE.Small,
    bigLottieFile,
    imagePosition,
    assetDimension,
    subTitleHTMLTag,
    iconTextListOrientation,
  } = data;

  const titleTag = titleHTMLTag?.value || "p";
  const subTitleTag = subTitleHTMLTag?.value || "p";
  const isFullWidth = moduleWidth?.size === "fullWidth";
  const isImageRight = imagePosition?.imagePosition === "right";

  const titleSizeClassName = {
    [TITLE_SIZE.Small]: "lg:text-5xl",
    [TITLE_SIZE.Big]: "lg:text-6xl",
  };

  return (
    <CoreContainer isFullWidth={isFullWidth} theme={theme?.value}>
      <section
        className={cx(
          "imgtxtcomp mx-auto flex w-full flex-col items-center text-[#0A1A1F] md:justify-between",
          isImageRight ? "sm:flex-row-reverse" : "sm:flex-row",
          isFullWidth ? "py-0 px-6 md:px-0" : "py-10 pt-0 md:py-20 md:pt-20"
        )}
      >
        <div className={cx("relative w-full sm:w-6/12")}>
          {mediaType === "picture" && picture && (
            <Picture
              picture={{ image: picture }}
              className="rounded-xs"
              layout="responsive"
              sizes="(max-width: 942px) 100vw, 50vw"
            />
          )}
          {mediaType === "bigLottieFile" && bigLottieFile ? (
            <div className="mb-4 !max-w-full">
              <LottieImage
                lottieFile={bigLottieFile}
                width="720px"
                height="600px"
                className="hidden lg:visible lg:block"
              />
              <LottieImage lottieFile={bigLottieFile} width={null} height={null} className="lg:hidden" />
            </div>
          ) : null}
          {mediaType === "video" && video?.video && (
            <SanityMuxPlayer
              assetDocument={video?.video?.asset}
              showControls={false}
              className="video-custom inset-0 !h-full w-full object-cover object-center"
              loop
              autoplay
              muted
            />
          )}
        </div>
        <div
          className={cx(
            "w-full sm:w-6/12",
            isImageRight ? "sm:flex-row-reverse" : "flex justify-start md:pl-14",
            isImageRight && isFullWidth ? "px-6 pt-12 md:pt-0 md:pl-24" : " px-12 pt-12 md:px-8 md:pl-0 md:pt-0",
            iconTextListOrientation === "horizontal" ? "!px-4 md:!px-8" : null
          )}
        >
          <div className="text-center sm:max-w-[600px] sm:px-6 md:px-8 md:text-left lg:px-12 xl:px-16">
            {Image || lottieFile ? (
              <div className="mb-4">
                <LottieImage
                  image={Image}
                  lottieFile={lottieFile}
                  width={assetDimension?.width}
                  height={assetDimension?.height}
                />
              </div>
            ) : null}
            {React.createElement(
              subTitleTag,
              {
                className:
                  "mb-2 text-center md:text-left whitespace-pre-wrap text-xs font-medium uppercase tracking-normal opacity-70",
              },
              <>{subTitle}</>
            )}
            {React.createElement(
              titleTag,
              {
                className: cx(
                  "mb-8 whitespace-pre-wrap text-center md:text-left text-4xl font-normal text-accent-800 sm:max-w-md md:max-w-none md:pr-4",
                  iconTextList?.length > 0 ? "pr-0" : "pr-4",
                  titleSizeClassName[titleSize]
                ),
              },
              <>{title}</>
            )}
            {text && (
              <div className="mt-4 mb-6 text-md font-light">
                {typeof text === "string" ? text : <BlockContent blocks={text} theme={theme?.value} />}
              </div>
            )}
            <div
              className={cx(
                iconTextListOrientation === "horizontal" ? "mt-8 flex content-center justify-center" : null
              )}
            >
              {iconTextList?.length > 0 &&
                iconTextList.map((item, i) => {
                  return (
                    <div className="mb-12 flex items-center" key={i}>
                      {(item?.image || item.lottieFile) && (
                        <div className={cx(iconTextListOrientation === "horizontal" ? "mr-1.5 md:mr-8" : "mr-8")}>
                          <LottieImage
                            image={item.image}
                            lottieFile={item.lottieFile}
                            width={item.assetDimension?.width}
                            height={item.assetDimension?.height}
                          />
                        </div>
                      )}
                      <div>
                        {item.title && (
                          <div className="pb-1 text-left text-xl font-normal text-accent-800 md:pb-3 lg:text-3xl">
                            <p>{item.title}</p>
                          </div>
                        )}
                        {item.subtitle && (
                          <div className="text-left text-sm font-light text-accent-800 lg:text-md">
                            <p>{item.subtitle}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <CustomLink {...link} />
          </div>
        </div>
      </section>
    </CoreContainer>
  );
};

ImageText.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    titleHTMLTag: PropTypes.shape({
      value: PropTypes.string,
    }),
    subTitle: PropTypes.string,
    subTitleHTMLTag: PropTypes.shape({
      value: PropTypes.string,
    }),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          style: PropTypes.string,
        })
      ),
    ]),
    picture: PropTypes.shape({}),
    link: PropTypes.shape({
      shouldScroll: PropTypes.bool,
      newWindow: PropTypes.bool,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    imagePosition: PropTypes.shape({
      imagePosition: PropTypes.string,
    }),
    moduleWidth: PropTypes.shape({
      size: PropTypes.string,
    }),
    assetDimension: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    image: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string,
      }),
    }),
    lottieFile: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string,
      }),
    }),
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark"]),
    }),
  }),
};

export default ImageText;
